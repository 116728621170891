<template>
    <page back>
        <el-form
            ref="addForm"
            size="small"
            :model="formModel"
            :rules="formRules"
        >
            <el-card
                size="small"
                shadow="never"
            >
                <div
                    slot="header"
                    class="clearfix"
                >
                    <span>基本信息</span>
                </div>
                <el-row
                    :gutter="10"
                    style="overflow: hidden"
                >
                    <el-col :span="18">
                        <el-form-item
                            prop="raffleName"
                            label="活动名称"
                            label-width="9em"
                        >
                            <el-input
                                v-model="formModel.raffleName"
                                auto-complete="off"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="raffleCover"
                            label="活动封面"
                            label-width="9em"
                        >
                            <upload
                                validate-md5
                                action="/rs/attachment/uploadFileLibrary"
                                :limit="1"
                                v-model="formModel.raffleCover"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="raffleAdImage"
                            label="活动广告图"
                            label-width="9em"
                        >
                            <upload
                                validate-md5
                                action="/rs/attachment/uploadFileLibrary"
                                :limit="5"
                                v-model="formModel.raffleAdImage"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="raffleDesc"
                            label="活动描述"
                            label-width="9em"
                        >
                            <el-input
                                type="textarea"
                                v-model="formModel.raffleDesc"
                                auto-complete="off"
                                rows="4"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="raffleTime"
                            label="活动时间"
                            label-width="9em"
                        >
                            <el-date-picker
                                v-model="formModel.raffleTime"
                                type="datetimerange"
                                format="yyyy-MM-dd HH:mm:ss"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :disabled="!(!formModel.id || formModel.raffleStatus === 10)"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="remarks"
                            label="备注"
                            label-width="9em"
                        >
                            <el-input
                                type="textarea"
                                v-model="formModel.remarks"
                                auto-complete="off"
                                rows="4"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
            <el-card
                size="small"
                shadow="never"
                class="ma-t"
            >
                <div
                    slot="header"
                    class="clearfix"
                >
                    <span>活动规则</span>
                    <el-dropdown
                        class="ma-l"
                        size="small"
                        @command="handleRuleType"
                        v-if="!formModel.id || formModel.raffleStatus === 10"
                    >
                        <el-button
                            type="success"
                            icon="el-icon-plus"
                            size="small"
                        >
                            新增
                        </el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="1">
                                拉新成功购买
                            </el-dropdown-item>

                        </el-dropdown-menu>
                    </el-dropdown>
                </div>

                <el-table
                    stripe
                    border
                    size="small"
                    :data="formModel.raffleRuleList"
                    style="width: 100%"
                >
                    <el-table-column
                        prop="raffleRuleType"
                        label="规则类型"
                        width="180"
                    >
                        <template #default="scope">
                            <dictionaries-picker
                                size="small"
                                type="raffleRuleType"
                                v-model="scope.row.raffleRuleType"
                                disabled
                            />
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="raffleRuleConfig"
                        label="规则配置"
                        min-width="200"
                    >
                        <template #default="scope">
                            <div v-if="+scope.row.raffleRuleType === 1">
                                <el-row>
                                    <el-col :span="12">
                                        拉新人数：
                                        <el-input-number
                                            controls-position="right"
                                            size="small"
                                            v-model="scope.row.personNum"
                                            :min="1"
                                            :disabled="!(!formModel.id || formModel.raffleStatus === 10)"
                                        />
                                    </el-col>
                                    <el-col :span="12">
                                        抽奖次数：
                                        <el-input-number
                                            controls-position="right"
                                            size="small"
                                            v-model="scope.row.raffleNum"
                                            :min="0"
                                            :disabled="!(!formModel.id || formModel.raffleStatus === 10)"
                                        />
                                    </el-col>
                                </el-row>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="isAvailable"
                        label="是否可用"
                        width="100"
                    >
                        <template #default="scope">
                            <el-switch
                                class="ma-l"
                                size="small"
                                v-model="scope.row.isAvailable"
                                :disabled="!(!formModel.id || formModel.raffleStatus === 10)"
                            />
                        </template>
                    </el-table-column>
                    <el-table-column
                        fixed="right"
                        label="操作"
                        width="60"
                        v-if="!formModel.id || formModel.raffleStatus === 10"
                    >
                        <template #default="scope">
                            <el-button
                                type="text"
                                size="small"
                                @click="deleteRaffleRule(scope.row)"
                            >
                                删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-card>

            <el-card
                size="small"
                shadow="never"
                class="ma-t"
            >
                <div
                    slot="header"
                    class="clearfix"
                >
                    <span>奖品信息</span>
                    <el-button
                        class="ma-l"
                        type="success"
                        size="small"
                        @click="addRafflePrize"
                        v-if="!formModel.id || formModel.raffleStatus === 10"
                    >
                        新 增
                    </el-button>
                </div>
                <el-table
                    stripe
                    border
                    size="small"
                    :data="formModel.rafflePrizeList"
                    style="width: 100%"
                >
                    <el-table-column
                        prop="rafflePrizeType"
                        label="奖品类型"
                        width="180"
                    >
                        <template #default="scope">
                            <dictionaries-picker
                                size="small"
                                type="rafflePrizeType"
                                v-model="scope.row.rafflePrizeType"
                                @change="changeRafflePrizeType(scope.row)"
                                :disabled="!(!formModel.id || formModel.raffleStatus === 10)"
                            />
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="rafflePrizeName"
                        label="奖品名称"
                        min-width="200"
                    >
                        <template #default="scope">
                            <el-input
                                size="small"
                                v-model="scope.row.rafflePrizeName"
                                auto-complete="off"
                                placeholder="请输入奖品名称"
                            />
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="rafflePrizeConfig"
                        label="奖品配置"
                        min-width="200"
                    >
                        <template #default="scope">
                            <div v-if="+scope.row.rafflePrizeType === 1">
                                现金：
                                <el-input-number
                                    size="small"
                                    v-model="scope.row.money"
                                    controls-position="right"
                                    :min="0"
                                    :precision="2"
                                    :disabled="!(!formModel.id || formModel.raffleStatus === 10)"
                                />
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="rafflePrizeImage"
                        label="奖品图片"
                        width="155"
                    >
                        <template #default="scope">
                            <upload
                                validate-md5
                                action="/rs/attachment/uploadFileLibrary"
                                :limit="1"
                                v-model="scope.row.rafflePrizeImage"
                                tips=""
                            />
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="rafflePrizeProbability"
                        label="奖品概率"
                        width="155"
                    >
                        <template #default="scope">
                            <el-input-number
                                size="small"
                                v-model="scope.row.rafflePrizeProbability"
                                controls-position="right"
                                :min="0"
                                :precision="4"
                                :disabled="!(!formModel.id || formModel.raffleStatus === 10)"
                            />
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="rafflePrizeNumber"
                        label="奖品数量"
                        width="155"
                    >
                        <template #default="scope">
                            <el-input-number
                                size="small"
                                v-model="scope.row.rafflePrizeNumber"
                                controls-position="right"
                                :min="0"
                                :disabled="!(!formModel.id || formModel.raffleStatus === 10)"
                            />
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="isAvailable"
                        label="是否可用"
                        width="100"
                    >
                        <template #default="scope">
                            <el-switch
                                class="ma-l"
                                size="small"
                                v-model="scope.row.isAvailable"
                            />
                        </template>
                    </el-table-column>
                    <el-table-column
                        fixed="right"
                        label="操作"
                        width="60"
                        v-if="!formModel.id || formModel.raffleStatus === 10"
                    >
                        <template #default="scope">
                            <el-button
                                type="text"
                                size="small"
                                @click="deleteRafflePrize(scope.row)"
                            >
                                删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-card>

            <el-form-item
                label-width="9em"
                class="ma-t"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onConfirm(false)"
                    v-if="!formModel.id || formModel.raffleStatus === 10"
                >
                    保 存
                </el-button>
                <el-button
                    type="primary"
                    size="small"
                    @click="onConfirm(true)"
                >
                    保存并提交
                </el-button>
                <el-button
                    size="small"
                    @click="$router.back()"
                >
                    返 回
                </el-button>
            </el-form-item>
        </el-form>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'RaffleEdit',
    mixins: [pagesMixin],
    data() {
        return {
            formModel: {
                id: '',
                raffleName: '',
                raffleCover: [],
                raffleAdImage: [],
                addressKeywords: '',
                raffleTime: [],
                raffleDesc: '',
                raffleStatus: '',
                remarks: '',
                rafflePrizeList: [],
                raffleRuleList: [],
            },
            formRules: {
                raffleName: [
                    {
                        required: true,
                        message: '请输入活动名称',
                        trigger: 'blur',
                    },
                ],
                raffleCover: [
                    {
                        required: true,
                        message: '请上传活动封面',
                        trigger: 'blur',
                    },
                ],
                raffleTime: [
                    {
                        required: true,
                        message: '请选择活动时间',
                    },
                ],
            },
        };
    },
    methods: {
        onConfirm(isSubmit) {
            const { addForm } = this.$refs;
            if (this.formModel.raffleRuleList && this.formModel.raffleRuleList.length) {
                for (let i = 0; i < this.formModel.raffleRuleList.length; i += 1) {
                    const item = this.formModel.raffleRuleList[i];

                    if (+item.raffleRuleType === 1) {
                        if (!item.personNum) {
                            this.$alert(`第${i + 1}行拉新人数不存在`, '温馨提示', {
                                type: 'error',
                            });
                            return;
                        }

                        if (!item.raffleNum) {
                            this.$alert(`第${i + 1}抽奖次数不存在`, '温馨提示', {
                                type: 'error',
                            });
                            return;
                        }
                    }
                }
            }
            if (this.formModel.rafflePrizeList && this.formModel.rafflePrizeList.length) {
                for (let i = 0; i < this.formModel.rafflePrizeList.length; i += 1) {
                    const item = this.formModel.rafflePrizeList[i];

                    if (+item.rafflePrizeType === 1) {
                        if (!item.rafflePrizeProbability) {
                            this.$alert(`第${i + 1}行奖品概率不存在`, '温馨提示', {
                                type: 'error',
                            });
                            return;
                        }
                        if (!item.rafflePrizeNumber) {
                            this.$alert(`第${i + 1}行奖品数量不存在`, '温馨提示', {
                                type: 'error',
                            });
                            return;
                        }
                    }
                }
            }
            addForm.validate().then(() => {
                this.$api.Pm.Raffle.save({
                    ...this.formModel,
                    isSubmit,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.$router.back();
                });
            });
        },
        initDetail() {
            this.$api.Pm.Raffle.getDetail({ id: this.formModel.id }).then(json => {
                const res = json.data.data;
                this.$utils.formModelMerge(this.formModel, res);
            });
        },

        // region raffleRule
        handleRuleType(ruleType) {
            if (+ruleType === 1) {
                if (this.formModel.raffleRuleList && this.formModel.raffleRuleList.length) {
                    for (let i = 0; i < this.formModel.raffleRuleList.length; i += 1) {
                        const row = this.formModel.raffleRuleList[i];
                        if (+row.raffleRuleType === +ruleType) {
                            this.$alert('该规则类型已存在', '温馨提示', {
                                type: 'error',
                            });
                            return;
                        }
                    }
                }
                this.formModel.raffleRuleList.push({
                    raffleRuleType: +ruleType,
                    personNum: undefined,
                    raffleNum: undefined,
                    isAvailable: true,
                });
            }
        },

        deleteRaffleRule(row) {
            this.formModel.raffleRuleList.splice(this.formModel.raffleRuleList.indexOf(row), 1);
        },
        // endregion

        // region rafflePrize
        addRafflePrize() {
            this.formModel.rafflePrizeList.push({
                rafflePrizeType: '',
                rafflePrizeName: undefined,
                rafflePrizeConfig: undefined,
                rafflePrizeImage: [],
                rafflePrizeProbability: undefined,
                rafflePrizeNumber: undefined,
                isAvailable: true,
            });
        },
        deleteRafflePrize(row) {
            this.formModel.rafflePrizeList.splice(this.formModel.rafflePrizeList.indexOf(row), 1);
        },
        changeRafflePrizeType(row) {
            if (+row.rafflePrizeType === 1) {
                row.rafflePrizeConfig = {
                    money: undefined,
                };
            } else {
                row.rafflePrizeConfig = '';
            }
        },
        // endregion
    },
    created() {
        this.formModel.id = this.$route.params.id;
        if (this.formModel.id) {
            this.initDetail();
        }
    },
};
</script>

<style lang="scss">
</style>
